<template>
  <div id="group-view">
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-title> Grupo {{ groupData.name }} </v-card-title>
          <group-panel :group-data="groupData" @already-save="onAlreadySave"></group-panel>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { onUnmounted, ref } from 'vue'
import router from '@/router'
import store from '@/store'

import GroupPanel from './group-panel/GroupPanel.vue'
import groupStoreModule from '../groupStoreModule'

let alreadySave = false

export default {
  components: {
    GroupPanel,
  },
  setup() {
    const appGroup = 'app-group'

    // Register module
    if (!store.hasModule(appGroup)) store.registerModule(appGroup, groupStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(appGroup)) store.unregisterModule(appGroup)
    })

    const groupData = ref({})

    const onAlreadySave = e => {
      alreadySave = e
    }

    if (router.currentRoute.params.id)
      store
        .dispatch('app-group/fetchGroup', { id: router.currentRoute.params.id })
        .then(response => {
          groupData.value = response.data.group
        })
        .catch(error => {
          if (error.response.status === 404) {
            groupData.value = {}
          }
        })

    return {
      groupData,
      onAlreadySave,
    }
  },
  async beforeRouteLeave(to, from, next) {
    if (!alreadySave) {
      const didConfirm = await store.dispatch('alertConfirm/CONFIRM', {
        title: 'Asegurese de guardar los cambios',
        message: 'No podrá recuperarlos',
      })
      if (didConfirm) {
        next()
      } else {
        next(false)
      }
    } else next()
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/apps/group.scss';
</style>
