<template>
  <v-row class="mt-1">
    <v-col cols="12">
      <!-- actions -->
      <v-card-text class="d-flex align-center flex-wrap pb-0">
        <!-- search -->
        <v-text-field v-model="search" placeholder="Buscar" outlined hide-details dense class="user-search me-3 mb-4">
        </v-text-field>

        <v-spacer></v-spacer>

        <div class="d-flex align-center flex-wrap">
          <v-btn color="primary" class="mb-4 me-3" @click.stop="addItem">
            <v-icon>{{ icons.mdiPlus }}</v-icon>
            <span>Agregar Materia</span>
          </v-btn>
        </div>
      </v-card-text>

      <v-data-table
        :headers="headers"
        :items="subjects"
        :footer-props="{
          'items-per-page-text': 'Materias por página: ',
          'items-per-page-options': [15, 30, 50, 100],
        }"
        :search="search"
        sort-by="name"
      >
        <!-- action -->
        <template #[`item.actions`]="{ item }">
          <v-icon small class="me-2" @click="editItem(item)">
            {{ icons.mdiPencilOutline }}
          </v-icon>
          <v-icon small @click="deleteItem(item)">
            {{ icons.mdiDeleteOutline }}
          </v-icon>
        </template>

        <template #[`item.teacher`]="{ item }">
          <div class="d-flex align-center">
            <span class="text-capitalize" v-if="item.teacherData"
              >{{ item.teacherData.name }} {{ item.teacherData.lastname }}</span
            >
          </div>
        </template>
        <template v-slot:footer.page-text="{ pageStart, pageStop, itemsLength }">
          {{ pageStart }}-{{ pageStop }} de {{ itemsLength }}
        </template>
        <template v-slot:no-data> No hay materias para mostrar </template>
      </v-data-table>
    </v-col>

    <!-- dialog -->
    <v-dialog v-model="dialog" :persistent="buttonLoading" max-width="1000px" persistent>
      <v-card>
        <v-card-title>
          <span class="headline">Materia {{ editedItem.name }}</span>
        </v-card-title>
        <v-tabs v-model="tab">
          <v-tab>Info General</v-tab>
          <v-tab>Dias de Clase</v-tab>
        </v-tabs>
        <v-form v-model="valid" ref="form" class="multi-col-validation" @submit.prevent="save">
          <v-card-text>
            <v-tabs-items v-model="tab">
              <v-tab-item>
                <v-container>
                  <v-row>
                    <v-col cols="12" sm="6" md="4">
                      <v-text-field
                        v-model="editedItem.name"
                        label="Nombre"
                        :rules="[validators.required]"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <v-text-field
                        v-model.number="editedItem.workLoad"
                        label="Carga Horaria"
                        type="number"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <v-select
                        v-model="editedItem.qualificationType"
                        :items="$store.state['app-group'].qualificationTypes"
                        label="Tipo de Calificación"
                        item-text="title"
                        item-value="value"
                        :rules="[validators.required]"
                      ></v-select>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <users-selection
                        v-model="editedItem.teacher"
                        :usersList="teachersList"
                        :rules="[validators.required]"
                        label="Docente"
                        dense
                      ></users-selection>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <v-file-input
                        v-model="syllabus"
                        multiple
                        hide-details
                        label="Subir Syllabus"
                        :rules="[validators.filesValidator(syllabus, 'all')]"
                      >
                      </v-file-input>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <v-chip
                        v-for="(syl, index) in editedItem.syllabus"
                        small
                        label
                        color="primary"
                        close
                        :key="syl.filename"
                        @click:close="deleteChip(index)"
                      >
                        <a
                          :href="
                            $store.state.apidomain +
                            '/group/uploads/syllabus/' +
                            syl.filename +
                            '.' +
                            syl.originalname.split('.')[syl.originalname.split('.').length - 1]
                          "
                          style="color: #fff; text-overflow: ellipsis; overflow: hidden"
                          >{{ syl.originalname }}</a
                        >
                      </v-chip>
                    </v-col>
                  </v-row>
                </v-container>
              </v-tab-item>
              <v-tab-item>
                <v-container>
                  <v-row>
                    <v-col cols="12">
                      <class-days v-model="editedItem.classDays"></class-days>
                    </v-col>
                  </v-row>
                </v-container>
              </v-tab-item>
            </v-tabs-items>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="error" outlined @click="close"> Cancelar </v-btn>
            <v-btn color="success" type="submit" :loading="buttonLoading" :disabled="buttonLoading"> Guardar </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import store from '@/store'
import UsersSelection from '@/views/components/UsersSelection.vue'
import { filesValidator, required } from '@core/utils/validation'
import { mdiDeleteOutline, mdiPencilOutline, mdiPlus } from '@mdi/js'
import { ref, watch } from 'vue'
import ClassDays from './ClassDays.vue'

export default {
  components: {
    UsersSelection,
    ClassDays,
  },
  props: {
    value: {
      default: [],
      type: Array,
    },
  },
  setup(props, { emit }) {
    const subjects = ref(JSON.parse(JSON.stringify(props.value)))

    const tab = ref('')
    const search = ref('')
    const teachersList = ref([])

    const editedIndex = ref(-1)
    const editedItem = ref({})
    const editedItemBackup = ref({})
    const defaultItem = {
      name: '',
      workLoad: null,
      teacher: '',
      qualificationType: '',
      syllabus: [],
      classDays: [],
    }
    const syllabus = ref([])
    const buttonLoading = ref(false)

    const dialog = ref(false)
    const dialogDelete = ref(false)
    const valid = ref(false)

    const headers = [
      { text: 'Nombre', value: 'name' },
      { text: 'Carga Horaria', value: 'workLoad' },
      { text: 'Docente', value: 'teacher' },
      { text: 'Acciones', value: 'actions' },
    ]

    const form = ref(null)

    const reset = () => {
      form.value.reset()
    }

    const resetValidation = () => {
      form.value.resetValidation()
    }

    const validate = () => {
      form.value.validate()
    }

    const addItem = () => {
      dialog.value = true
      editedItem.value = { ...defaultItem }
      editedIndex.value = -1
    }

    const editItem = item => {
      editedIndex.value = subjects.value.indexOf(item)
      editedItemBackup.value = { ...JSON.parse(JSON.stringify(item)) }
      editedItem.value = { ...JSON.parse(JSON.stringify(item)) }
      dialog.value = true
    }

    const close = async () => {
      if (Object.keys(editedItemBackup.value).length) {
        if (JSON.stringify(editedItemBackup.value) !== JSON.stringify(editedItem.value)) {
          const didConfirm = await store.dispatch('alertConfirm/CONFIRM', {
            title: '¿Está seguro que desea cerrar?',
            message: 'Perderá los cambios realizados',
          })
          if (didConfirm) {
            dialog.value = false
            editedItemBackup.value = {}
          }
        } else {
          dialog.value = false
          editedItemBackup.value = {}
        }
      } else {
        if (JSON.stringify(editedItem.value) !== JSON.stringify(defaultItem)) {
          const didConfirm = await store.dispatch('alertConfirm/CONFIRM', {
            title: '¿Está seguro que desea cerrar?',
            message: 'Perderá los cambios realizados',
          })
          if (didConfirm) {
            if (!buttonLoading.value) {
              editedItem.value = { ...defaultItem }
              editedIndex.value = -1
              dialog.value = false
            }
          }
        } else {
          if (!buttonLoading.value) {
            editedItem.value = { ...defaultItem }
            editedIndex.value = -1
            dialog.value = false
          }
        }
      }
    }

    const deleteItem = async item => {
      const didConfirm = await store.dispatch('alertConfirm/CONFIRM', {
        title: '¿Está seguro que desea borrar la materia?',
        message: 'No podrá recuperarla',
      })
      if (didConfirm) {
        subjects.value.splice(subjects.value.indexOf(item), 1)
        emit('input', subjects.value)
      }
    }

    const deleteChip = index => {
      editedItem.value.syllabus.splice(index, 1)
    }

    const save = async () => {
      buttonLoading.value = true
      if (valid.value) {
        if (syllabus.value.length > 0) {
          await uploadFiles(syllabus.value)
          syllabus.value = []
        }
        editedItem.value.teacherData = teachersList.value.find(teacher => teacher._id == editedItem.value.teacher)
        if (editedIndex.value > -1) {
          Object.assign(subjects.value[editedIndex.value], editedItem.value)
          editedItemBackup.value = {}
        } else {
          subjects.value.push(editedItem.value)
        }
        emit('input', subjects.value)
        buttonLoading.value = false
        dialog.value = false
      } else {
        buttonLoading.value = false
        validate()
      }
    }

    store
      .dispatch('user/fetchUsers', {
        role: 'admin,teacher',
      })
      .then(response => {
        const { filteredData } = response.data

        teachersList.value = filteredData
      })
      .catch(error => {
        console.log(error)
      })

    const uploadFiles = async files => {
      let formdata = new FormData()

      files.forEach(file => {
        formdata.append('syllabus', file)
      })

      let r = await store.dispatch('app-group/uploadFiles', formdata)

      if (r.status == 200) {
        if (r.data.files.length > 0)
          r.data.files.forEach(file => {
            editedItem.value.syllabus.push({
              originalname: file.originalname,
              filename: file.filename,
              path: file.path,
            })
          })
      } else snackbarPlugin.showMessage({ content: r.data.message, color: 'error', timeout: 10000 })
    }

    return {
      form,
      search,
      dialog,
      dialogDelete,
      editedIndex,
      editedItem,
      defaultItem,
      deleteItem,
      deleteChip,
      teachersList,
      save,
      close,
      headers,
      editItem,
      addItem,
      tab,
      valid,
      validate,
      validators: { required, filesValidator },
      subjects,
      syllabus,
      buttonLoading,

      // icons
      icons: {
        mdiDeleteOutline,
        mdiPencilOutline,
        mdiPlus,
      },
    }
  },
}
</script>
