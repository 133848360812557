<template>
  <v-row class="mt-1">
    <v-col cols="12">
      <!-- actions -->
      <v-card-text class="d-flex align-center flex-wrap pb-0">
        <!-- search -->
        <v-text-field v-model="search" placeholder="Buscar" outlined hide-details dense class="user-search me-3 mb-4">
        </v-text-field>

        <v-spacer></v-spacer>

        <div class="d-flex align-center flex-wrap">
          <v-btn color="primary" class="mb-4 me-3" @click.stop="dialogs.addStudent = true">
            <v-icon>{{ icons.mdiPlus }}</v-icon>
            <span>Agregar Alumnos</span>
          </v-btn>
          <v-btn color="success" dark @click="dialogs.importList = true" class="mb-4 me-3">
            Importar
            <v-icon dark right>
              {{ icons.mdiCloudUploadOutline }}
            </v-icon>
          </v-btn>
          <v-btn color="secondary" dark @click="exportSelectedStudents" :disabled="!students.length" class="mb-4 me-3">
            <v-icon size="17" class="me-1">
              {{ icons.mdiExportVariant }}
            </v-icon>
            <span>Exportar</span>
          </v-btn>
        </div>

        <ImportListTool
          v-model="dialogs.importList"
          :columns="importColumns"
          :fileSize="50000000"
          @result="uploadBatch"
        />

        <v-dialog v-model="dialogs.addStudent" persistent width="500">
          <v-card>
            <v-card-title class="headline"> Agregar Alumnos </v-card-title>
            <v-card-text>
              <users-selection
                v-model="studentsToAdd"
                :usersList="usersList"
                label="Seleccione alumnos"
                multiple
                dense
              ></users-selection>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="error" outlined @click="dialogs.addStudent = false"> Cerrar </v-btn>
              <v-btn color="success" @click="addStudents"> Guardar </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-card-text>

      <v-data-table
        :headers="headers"
        :items="students"
        :footer-props="{
          'items-per-page-text': 'Alumnos por página: ',
          'items-per-page-options': [15, 30, 50, 100],
        }"
        :search="search"
        :custom-filter="customFilter"
        sort-by="name"
      >
        <template #[`item.name`]="{ item }">
          <router-link
            :to="item.studentData._id ? { name: 'apps-user-view', params: { id: item.studentData._id } } : '#'"
            :target="item.studentData._id ? '_blank' : ''"
            class="text--primary font-weight-semibold text-truncate cursor-pointer text-decoration-none"
          >
            <span>{{ item.studentData.name }} {{ item.studentData.lastname }}</span>
          </router-link>
        </template>
        <template v-slot:item.assisted="{ item }">
          <span>{{ item.attendancePercentage }}%</span>
          <v-progress-linear
            height="6"
            rounded
            class="project-progress mt-1"
            :color="resolveUserProgressVariant(item.attendancePercentage)"
            :value="item.attendancePercentage"
          ></v-progress-linear>
        </template>
        <template v-slot:item.average="{ item }">
          <span>{{ item.qualyTotal }}</span>
        </template>

        <!-- action -->
        <template #[`item.actions`]="{ item }">
          <v-icon small class="me-2" @click="editItem(item)">
            {{ icons.mdiPencilOutline }}
          </v-icon>
          <v-icon small @click="deleteItem(item)">
            {{ icons.mdiDeleteOutline }}
          </v-icon>
        </template>

        <template v-slot:footer.page-text="{ pageStart, pageStop, itemsLength }">
          {{ pageStart }}-{{ pageStop }} de {{ itemsLength }}
        </template>
        <template v-slot:no-data> No hay alumnos para mostrar </template>
      </v-data-table>
      <v-dialog v-model="dialogs.editStudent" persistent width="700">
        <v-card>
          <v-card-title class="headline">{{
            editedItem.studentData ? `${editedItem.studentData.name} ${editedItem.studentData.lastname}` : ''
          }}</v-card-title>
          <v-tabs v-model="tab">
            <v-tab>Asistencias</v-tab>
            <v-tab>Calificaciónes</v-tab>
          </v-tabs>
          <v-form v-model="valid" ref="form" class="multi-col-validation" @submit.prevent="save">
            <v-card-text>
              <v-tabs-items v-model="tab">
                <v-tab-item
                  ><v-simple-table>
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th>Fecha</th>
                          <th>Materia</th>
                          <th>Asistencia</th>
                          <th>Observaciónes</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(attendance, index) in editedItem.attendances" :key="index">
                          <td>{{ getClassDayDate(attendance.classDay) }}</td>
                          <td>{{ getClassDaySubject(attendance.classDay) }}</td>
                          <td>
                            <v-switch v-model="attendance.status" color="success" hide-details></v-switch>
                          </td>
                          <td>
                            <v-textarea
                              v-model="attendance.observation"
                              auto-grow
                              outlined
                              rows="1"
                              row-height="15"
                              class="mt-2"
                            ></v-textarea>
                          </td>
                        </tr>
                        <tr v-if="!editedItem.attendances.length">
                          <td>No hay asistencias para mostrar</td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table></v-tab-item
                >
                <v-tab-item
                  ><v-simple-table>
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th>Fecha</th>
                          <th>Materia</th>
                          <th>Calificación</th>
                          <th>Observaciónes</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(qualification, index) in editedItem.qualifications" :key="index">
                          <td>{{ date(qualification.date).format('DD/MM/YYYY HH:mm') }}</td>
                          <td>{{ $props.groupData.subjects.find(sub => sub._id == qualification.subject).name }}</td>
                          <td
                            style="width: 120px"
                            v-if="
                              $props.groupData.subjects.find(sub => sub._id == qualification.subject)
                                .qualificationType == 'course'
                            "
                          >
                            <v-text-field
                              v-model.number="qualification.qualification"
                              type="number"
                              class="mt-2"
                              outlined
                              :rules="[validators.qualyValidator]"
                            ></v-text-field>
                          </td>
                          <td
                            style="width: 160px"
                            v-else-if="
                              $props.groupData.subjects.find(sub => sub._id == qualification.subject)
                                .qualificationType == 'workshop'
                            "
                          >
                            <v-select
                              :items="$store.state['app-group'].workshopQualy"
                              v-model="qualification.qualification"
                              item-text="title"
                              item-value="value"
                              hide-details
                            ></v-select>
                          </td>
                          <td>
                            <v-textarea
                              v-model="qualification.observation"
                              auto-grow
                              outlined
                              rows="1"
                              row-height="15"
                              class="mt-2"
                            ></v-textarea>
                          </td>
                        </tr>
                        <tr v-if="!editedItem.qualifications.length">
                          <td>No hay calificaciónes para mostrar</td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-tab-item>
              </v-tabs-items>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="error" outlined @click="close"> Cerrar </v-btn>
              <v-btn color="success" type="submit"> Guardar </v-btn>
            </v-card-actions>
          </v-form>
        </v-card>
      </v-dialog>
    </v-col>
  </v-row>
</template>

<script>
import store from '@/store'
import { qualyValidator, required, requiredSelect } from '@core/utils/validation'
import { mdiExportVariant, mdiCloudUploadOutline, mdiDeleteOutline, mdiPencilOutline, mdiPlus } from '@mdi/js'
import { ref } from 'vue'
import { exportData } from '@/utils'
import ImportListTool from '@/views/components/ImportList/ImportListTool.vue'
import UsersSelection from '@/views/components/UsersSelection.vue'
import snackbarPlugin from '@/plugins/snackbar'
import { inject } from 'vue'

export default {
  components: {
    ImportListTool,
    UsersSelection,
  },
  props: {
    value: {
      default: [],
      type: Array,
    },
    groupData: {
      default: {},
      type: Object,
    },
  },
  setup(props, { emit }) {
    const students = ref(JSON.parse(JSON.stringify(props.value)))
    const search = ref('')
    const studentsToAdd = ref([])
    const date = inject('date')
    const usersList = ref([])

    const editedIndex = ref(-1)
    const editedItem = ref({})
    const editedItemBackup = ref({})
    const buttonLoading = ref(false)

    const dialog = ref(false)
    const dialogDelete = ref(false)
    const valid = ref(false)
    const tab = ref('')

    const dialogs = ref({
      importList: false,
      addStudent: false,
      editStudent: false,
    })

    const headers = [
      { text: 'Nombre', value: 'name' },
      { text: 'Promedio', value: 'average' },
      { text: 'Asistido', value: 'assisted' },
      { text: 'Acciones', value: 'actions' },
    ]

    const form = ref(null)

    const reset = () => {
      form.value.reset()
    }

    const resetValidation = () => {
      form.value.resetValidation()
    }

    const validate = () => {
      form.value.validate()
    }

    const editItem = item => {
      editedIndex.value = students.value.indexOf(item)
      editedItemBackup.value = { ...JSON.parse(JSON.stringify(item)) }
      editedItem.value = { ...JSON.parse(JSON.stringify(item)) }
      dialogs.value.editStudent = true
    }

    const close = async () => {
      if (Object.keys(editedItemBackup.value).length) {
        if (JSON.stringify(editedItemBackup.value) !== JSON.stringify(editedItem.value)) {
          const didConfirm = await store.dispatch('alertConfirm/CONFIRM', {
            title: '¿Está seguro que desea cerrar?',
            message: 'Perderá los cambios realizados',
          })
          if (didConfirm) {
            dialogs.value.editStudent = false
          }
        } else {
          dialogs.value.editStudent = false
        }
      }
    }

    const deleteItem = async item => {
      const didConfirm = await store.dispatch('alertConfirm/CONFIRM', {
        title: '¿Está seguro que desea borrar este alumno?',
        message: 'No podrá recuperar su información',
      })
      if (didConfirm) {
        students.value.splice(students.value.indexOf(item), 1)
        emit('input', students.value)
      }
    }

    const save = async () => {
      if (valid.value) {
        if (editedIndex.value > -1) {
          Object.assign(students.value[editedIndex.value], editedItem.value)
        }
        emit('input', students.value)
        dialogs.value.editStudent = false
      } else {
        validate()
      }
    }

    const addStudents = () => {
      studentsToAdd.value.forEach(student => {
        student = usersList.value.find(u => u._id == student)
        if (students.value.findIndex(st => st.studentData.document == student.document) == -1)
          students.value.push({
            qualifications: [],
            attendances: [],
            observations: [],
            attendancePercentage: 0,
            qualyTotal: 'Pendiente',
            studentData: student,
            student: student._id,
          })
      })
      dialogs.value.addStudent = false
      emit('input', students.value)
      snackbarPlugin.showMessage({
        content: studentsToAdd.value.length > 1 ? 'Alumnos agregados correctamente' : 'Alumno agregado correctamente',
        color: 'success',
        timeout: 10000,
      })
      studentsToAdd.value = []
    }

    const exportSelectedStudents = async () => {
      let data = students.value.map(e => ({
        Documento: e.studentData.document,
        Nombre: e.studentData.name,
        Apellido: e.studentData.lastname,
        Email: e.studentData.email || '',
        'Fecha de Nacimiento': e.studentData.birthday || '',
        Direccion: e.studentData.address || '',
        Telefono: e.studentData.phone || '',
        Asistido: e.attendancePercentage + '%' || '',
        Promedio: e.qualyTotal || '',
      }))

      exportData(data, `${props.groupData.name}-Alumnos.csv`)
    }

    const uploadBatch = stds => {
      stds.forEach(s => {
        s.password = s.document
        s.role = 'student'
        if (students.value.findIndex(st => st.studentData.document == s.document) == -1)
          students.value.push({
            qualifications: [],
            attendances: [],
            observations: [],
            attendancePercentage: 0,
            qualyTotal: 'Pendiente',
            studentData: s,
          })
      })

      emit('studentsToSave', stds)
      emit('input', students.value)
    }

    const capitalize = str =>
      str.toLowerCase().replace(/\b([a-zÁ-ú]{3,})/g, w => w.charAt(0).toUpperCase() + w.slice(1))

    const importColumns = ref([
      {
        value: 'document',
        text: 'Documento',
        replace: function (e) {
          return String(e).replace(/\D/g, '')
        },
        required: true,
      },
      {
        value: 'name',
        text: 'Nombre',
        replace: function (e) {
          //Remplazo por la primera letra en mayúscula y las demás en minúscula
          return capitalize(String(e))
        },
        validation: function (e) {
          return (
            //eslint-disable-next-line
            (e && String(e).trim() !== '' && /^[A-zÀ-ú_ ]*$/.test(String(e))) || `Nombre inválido`
          )
        },
        required: true,
      },
      {
        value: 'lastname',
        text: 'Apellido',
        replace: function (e) {
          //Remplazo por la primera letra en mayúscula y las demás en minúscula
          return capitalize(String(e))
        },
        validation: function (e) {
          return (
            //eslint-disable-next-line
            (e && String(e).trim() !== '' && /^[A-zÀ-ú_ ]*$$/.test(String(e))) || `Apellido inválido`
          )
        },
        required: true,
      },
      {
        value: 'phone',
        text: 'Teléfono',
        validation: function (e) {
          return true
        },
      },
      {
        value: 'address',
        text: 'Dirección',
        replace: function (e) {
          //Remplazo por la primera letra en mayúscula y las demás en minúscula
          return capitalize(String(e))
        },
      },
      {
        value: 'email',
        text: 'Email',
        replace: function (e) {
          return String(e).toLowerCase()
        },
        validation: function (e) {
          if (e && String(e).length)
            return (
              //eslint-disable-next-line
              /^[\w-\.]+@([\w-]+\.)+[\w-]{2,10}$/.test(e) || `Formato de email inválido`
            )
          else return true
        },
      },
    ])

    const resolveUserProgressVariant = progrss => {
      if (progrss <= 25) return 'error'
      if (progrss > 25 && progrss <= 50) return 'warning'
      if (progrss > 50 && progrss <= 75) return 'primary'
      if (progrss > 75 && progrss <= 100) return 'success'

      return 'secondary'
    }

    const customFilter = (value, search, item) => {
      const document = item.studentData.document
      const name = item.studentData.name.toLowerCase()
      const lastname = item.studentData.lastname.toLowerCase()
      const searchText = search.toLowerCase()

      return document.indexOf(searchText) > -1 || name.indexOf(searchText) > -1 || lastname.indexOf(searchText) > -1
    }

    const getClassDayDate = classDay => {
      let classDate = ''

      props.groupData.subjects.forEach(s => {
        const classD = s.classDays.filter(cd => cd._id == classDay)
        if (classD.length) {
          classDate = `${date(classD.at(0).start).format('DD/MM/YYYY')} ${date(classD.at(0).start).format('HH:mm')} -
                      ${date(classD.at(0).end).format('HH:mm')}`
          return
        }
      })

      return classDate
    }

    store
      .dispatch('user/fetchUsers', {
        role: 'student,teacher,companySupervisor',
      })
      .then(response => {
        const { filteredData } = response.data

        usersList.value = filteredData
      })
      .catch(error => {
        console.log(error)
      })

    const getClassDaySubject = classDay => {
      return props.groupData.subjects.find(sub => sub.classDays.some(cd => cd._id == classDay)).name
    }

    return {
      date,
      getClassDayDate,
      getClassDaySubject,
      customFilter,
      addStudents,
      studentsToAdd,
      usersList,
      resolveUserProgressVariant,
      uploadBatch,
      exportSelectedStudents,
      form,
      search,
      dialog,
      dialogDelete,
      editedIndex,
      editedItem,
      deleteItem,
      save,
      close,
      headers,
      editItem,
      valid,
      validate,
      validators: { required, qualyValidator, requiredSelect},
      students,
      buttonLoading,
      importColumns,
      dialogs,
      tab,

      // icons
      icons: {
        mdiDeleteOutline,
        mdiPencilOutline,
        mdiPlus,

        mdiCloudUploadOutline,
        mdiExportVariant,
      },
    }
  },
}
</script>
