<template>
  <div>
    <v-tabs v-model="tab">
      <v-tab>Info General</v-tab>
      <v-tab>Materias</v-tab>
      <v-tab>Alumnos</v-tab>
    </v-tabs>

    <v-card flat>
      <v-form v-model="valid" ref="form" class="multi-col-validation" @submit.prevent="onSubmit">
        <v-card-text>
          <v-tabs-items v-model="tab">
            <v-tab-item>
              <v-row class="mt-1">
                <v-col md="6" cols="12">
                  <v-text-field
                    v-model="group.name"
                    :rules="[validators.required]"
                    outlined
                    dense
                    hide-details
                    label="Nombre del Grupo"
                    disabled
                  ></v-text-field>
                </v-col>

                <v-col cols="12" md="6">
                  <companies-selection
                    v-model="group.company"
                    class="mb-6"
                    @change="onSelectCompany"
                    :disabled="!!$props.groupData._id"
                  ></companies-selection>
                </v-col>

                <v-col md="6" cols="12">
                  <v-text-field
                    v-model="group.courseName"
                    :rules="[validators.required]"
                    outlined
                    dense
                    hide-details
                    label="Nombre del Curso"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" md="6">
                  <v-select
                    v-model="group.courseModality"
                    :rules="[validators.requiredSelect]"
                    :items="$store.state['app-group'].modalityOptions"
                    label="Modalidad del Curso"
                    item-text="title"
                    item-value="value"
                    outlined
                    dense
                    hide-details
                  ></v-select>
                </v-col>

                <v-col md="6" cols="12">
                  <v-text-field v-model="group.reference" outlined dense hide-details label="Referencia"></v-text-field>
                </v-col>
              </v-row>
            </v-tab-item>

            <v-tab-item>
              <subjects v-model="group.subjects"></subjects>
            </v-tab-item>

            <v-tab-item>
              <students
                v-model="group.students"
                :groupData="group"
                @studentsToSave="users => (studentsToSave = studentsToSave.concat(users))"
              ></students>
            </v-tab-item>
          </v-tabs-items>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn color="primary" type="submit" :loading="buttonLoading" :disabled="buttonLoading"> Guardar </v-btn>
          <v-btn color="secondary" outlined :to="{ name: 'apps-group-list' }"> Volver </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </div>
</template>

<script>
import snackbarPlugin from '@/plugins/snackbar'
import { required, requiredSelect } from '@core/utils/validation'
import { mdiEyeOffOutline, mdiEyeOutline } from '@mdi/js'
import { ref, watch, inject, onUnmounted } from 'vue'
import CompaniesSelection from '@/views/components/CompaniesSelection.vue'

import store from '@/store'

import Subjects from './Subjects/Subjects.vue'
import Students from './Students/Students.vue'

export default {
  components: {
    Subjects,
    Students,
    CompaniesSelection,
  },
  props: {
    groupData: {
      type: Object,
      required: true,
    },
  },
  setup(props, { emit }) {

    const group = ref({
      name: '',
      courseName: '',
      courseModality: '',
      company: '',
      reference: '',
      subjects: [],
      students: [],
    })
    const groupBckp = ref({})
    const students = ref([])
    const date = inject('date')
    const studentsToSave = ref([])

    const tab = ref('')

    const valid = ref(false)
    const form = ref(null)
    const buttonLoading = ref(false)

    const reset = () => {
      form.value.reset()
    }

    const resetValidation = () => {
      form.value.resetValidation()
    }

    const validate = () => {
      form.value.validate()
    }

    const onSelectCompany = company => {
      const groupNumber = company.groups.map(g => g.name.match(/\d+/g)[1])
      group.value.name = `${date().format('YY')}-${companyInitials(company.fantasyName)}-${threeDigits(
        groupNumber.length ? Math.max(...groupNumber) : 0,
      )}`
    }

    const companyInitials = value => {
      const nameArray = value.replace(/[^a-zA-Z0-9 ]/g, '').split(' ')

      if (nameArray.length == 1) {
        return nameArray[0].substring(0, 3).toUpperCase()
      } else if (nameArray.length < 4) {
        return nameArray.map(word => word.substring(0, 2).toUpperCase()).join('')
      } else if (nameArray.length > 3) {
        return nameArray.map(word => word.substring(0, 1).toUpperCase()).join('')
      }
    }

    const threeDigits = value => {
      let len = value.toString().length
      if (len == 1) return `00${value + 1}`
      else if (len == 2) return `0${value + 1}`
      else return `${value + 1}`
    }

    // on form submit
    const onSubmit = async () => {
      if (valid.value) {
        buttonLoading.value = true

        await saveStudentsUploaded()

        if (group.value._id)
          store
            .dispatch('app-group/updateGroup', { group: group.value, groupBckp: groupBckp.value })
            .then(r => {
              groupBckp.value = JSON.parse(JSON.stringify(group.value))
              emit('already-save', true)
              snackbarPlugin.showMessage({ content: 'Guardado Correctamente', color: 'success', timeout: 10000 })

              buttonLoading.value = false
            })
            .catch(e => {
              buttonLoading.value = false
              snackbarPlugin.showMessage({ content: e.response.data.message, color: 'error', timeout: 10000 })
            })
        else
          store
            .dispatch('app-group/addGroup', group.value)
            .then(r => {
              emit('already-save', true)
              snackbarPlugin.showMessage({ content: 'Guardado Correctamente', color: 'success', timeout: 10000 })
              group.value = r.data.group
              buttonLoading.value = false
            })
            .catch(e => {
              buttonLoading.value = false
              snackbarPlugin.showMessage({ content: e.response.data.message, color: 'error', timeout: 10000 })
            })
      } else {
        validate()
      }
    }

    const saveStudentsUploaded = async () => {
      if (studentsToSave.value.length > 0) {
        let result = await store.dispatch('user/addBatchUsers', studentsToSave.value)
        group.value.students.forEach(st => {
          if (!st.student) {
            st.student = result.data.result.find(s => s.document == st.studentData.document).result._id
          }
        })
        console.log(result)
      }
    }

    watch(
      () => props.groupData,
      value => {
        group.value = value
        groupBckp.value = JSON.parse(JSON.stringify(value))
        students.value = group.value.students
          ? group.value.students.map(student => {
              return student.student
            })
          : []
      },
    )

    return {
      onSelectCompany,
      students,
      group,
      form,
      tab,
      onSubmit,
      valid,
      validate,
      validators: { required, requiredSelect },
      buttonLoading,
      studentsToSave,

      // icons
      icons: {
        mdiEyeOutline,
        mdiEyeOffOutline,
      },
    }
  },
}
</script>
